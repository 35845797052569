<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-jingshi"
                size="24"
                color="#FF9B77"></Icon>
          当前班次完成率计算方式：里程完成率：{{mileage}}%;
          <Button type="text"
                  class="m-l-5"
                  @click.stop="onClickComplete">设置完成度计算</Button>
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="selectType"
                    placeholder="查询分类"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in selectTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-model="carType"
                    clearable
                    label-in-value
                    placeholder="车辆类型"
                    class="m-r-10"
                    style="width:160px"
                    @on-change="onChangeCarType">
              <Option v-for="item in carTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
            <Select v-model="remoteCar"
                    filterable
                    clearable
                    placeholder="请输入车牌号"
                    :remote-method="remoteMethod"
                    :loading="remoteCarLoading"
                    class="m-r-10"
                    style="width:160px"
                    @on-query-change="onQueryChangeRemoteCar">
              <Option v-for="(option, index) in remoteCarList"
                      :value="option.id"
                      :key="option.id">{{option.name}}</Option>
            </Select>
            <template v-if="currentTabIndex===0">
              <Input v-model="planName"
                     clearable
                     placeholder="计划名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <!-- <template v-if="currentTabIndex===1">
              <Input v-model="siteName2"
                     clearable
                     placeholder="中转站/收集站名称"
                     class="m-r-10"
                     style="width:160px" />
              <Select v-model="transitType"
                      placeholder="转运类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in transitTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===2">
              <Select v-model="siteType"
                      clearable
                      placeholder="处理站类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in siteTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="siteName3"
                     clearable
                     placeholder="处理站名称"
                     class="m-r-10"
                     style="width:160px" />
            </template> -->
            <template v-if="currentTabIndex===1">
              <Select v-model="violateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in violateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:260px"
                        v-if="selectType===1"></DatePicker>
            <DatePicker :value="dateMonth"
                        type="month"
                        placeholder="选择月份"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:160px"
                        v-if="selectType===2"></DatePicker>
          </div>
          <div class="flex">
            <template>
              <template v-if="selectType===1">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                        :ghost="buttonIndex == 0 ? false : true"
                        @click.stop="onClickToday">今天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                        :ghost="buttonIndex == 1 ? false : true"
                        @click.stop="onClickYesterday">昨天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                        :ghost="buttonIndex == 2 ? false : true"
                        @click.stop="onClickNearlyThreeDay">近三天</Button>
              </template>
              <template v-if="selectType===2">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                        :ghost="buttonIndex == 3 ? false : true"
                        @click.stop="onClickCurrentMonth">本月</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                        :ghost="buttonIndex == 4 ? false : true"
                        @click.stop="onClickLastMonth">上月</Button>
              </template>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-work/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content"
         v-if="currentTabIndex == 1">
      <Echarts ref="echarts"
               height="368px"
               :options="options"
               :data="violateChartData"
               v-show="violateChartData.length"></Echarts>
      <div class="noData"
           v-show="violateChartData.length===0">
        <img src="../../../../assets/common/tubiaono.png">
        暂无数据
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div>
          <!-- <Dropdown>
            <Button class="m-r-10"
            v-hasPermi="['/admin/garbageTransit-work/export']">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> -->
        </div>
        <div class="flex a-center j-between">
          <Tabs v-model="currentCarWorkTabIndex"
                class="tabs"
                key="child1"
                style="flex:1;">
            <TabPane v-for="item in carWorkTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentCarWorkTabIndex==0"></TabPane>
          </Tabs>
          <!-- <div v-show="currentCarWorkTabIndex===1"
               class="flex a-center">
            完成状态：
            <RadioGroup v-model="status"
                        @on-change="onChangeRadio">
              <Radio :label="0">
                全部
              </Radio>
              <Radio :label="3">
                已完成
              </Radio>
              <Radio :label="1">
                未完成
              </Radio>
            </RadioGroup>
          </div> -->
        </div>
      </template>
      <!-- <template v-if="currentTabIndex===1">
        <Tabs v-model="currentTransitTabIndex"
              class="tabs"
              key="child2">
          <TabPane v-for="item in transitTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <template v-if="currentTabIndex===2">
        <Tabs v-model="currentDisposalTabIndex"
              class="tabs"
              key="child3">
          <TabPane v-for="item in disposalTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template> -->
      <template v-if="currentTabIndex===1">
        <Tabs v-model="currentViolationTabIndex"
              class="tabs"
              key="child4">
          <TabPane v-for="item in violationTabArr"
                   :key="item.name"
                   :label="item.name"
                   :disabled="currentViolationTabIndex==0"></TabPane>
        </Tabs>
      </template>
      <div class="table">
        <Table ref="table"
               :width="tableWidth"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop=""
                    type="text">处理</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"></PositionDetailModal>

    <!-- 完成度设置 -->
    <GridFinishDegreeModal v-model="gridCompleteVisible"
                           :type.sync="gridCompleteType"
                           @onClickConfirm="getCompleteness"></GridFinishDegreeModal>

    <!-- 车辆作业情况 详情列表的弹窗 -->
    <MechanicalCleanPlanDetailModal v-model="mechanicalCleanPlanDetailVisible"
                                    :item.sync="mechanicalCleanPlanDetailItem"
                                    :workType="mechanicalCleanPlanDetailWorkType"
                                    :date="date"></MechanicalCleanPlanDetailModal>

  </div>
</template>

<script>
import { getCurrentMonth, getToday, getYesterday, getNearlyThreeDay, getLastMonth, formatSecond } from '@/utils/dateRangUtil'
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import Echarts from '@/components/common/Echarts';
import GridFinishDegreeModal from '@/components/product/admin/modal/work/GridFinishDegreeModal'
import { checkPermi } from '@/utils/permission'
import MechanicalCleanPlanDetailModal from '@/components/product/admin/modal/work/MechanicalCleanPlanDetailModal'
export default {
  components: {
    MyTreeSelect,
    PositionDetailModal,
    Echarts,
    GridFinishDegreeModal,
    MechanicalCleanPlanDetailModal
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '车辆作业统计'
        },
        // {
        //   name: '中转/收集站统计'
        // },
        // {
        //   name: '处理站统计'
        // },
        {
          name: '违规统计'
        },
      ],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      carType: '',
      carTypeName: '',
      // carTypeArr: [],
      carNumber: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departId: '',
      departmentName: '',
      departmentId: '',
      planName: '',
      siteName1: '',
      siteName2: '',
      siteName3: '',
      transitType: -1,
      transitTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '转入'
        },
        {
          id: 2,
          name: '转出'
        },
      ],
      siteType: -1,
      siteTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '填埋场'
        },
        {
          id: 2,
          name: '焚烧场'
        },
      ],
      violateType: '',
      violateTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '离岗'
        },
        {
          id: 2,
          name: '停留'
        },
        {
          id: 3,
          name: '聚众'
        },
      ],
      date: [],
      dateMonth: '',
      status: 0,
      currentCarWorkTabIndex: 0,
      carWorkTabArr: [
        {
          name: '作业汇总'
        },
        {
          name: '作业明细'
        },
        // {
        //   name: '纠正记录'
        // }
      ],
      currentTransitTabIndex: 0,
      transitTabArr: [
        {
          name: '转运汇总'
        },
        {
          name: '转运明细'
        },
        {
          name: '纠正记录'
        }
      ],
      currentDisposalTabIndex: 0,
      disposalTabArr: [
        {
          name: '处置汇总'
        },
        {
          name: '处置明细'
        },
        {
          name: '纠正记录'
        }
      ],
      currentViolationTabIndex: 0,
      violationTabArr: [
        {
          name: '违规汇总'
        },
        {
          name: '违规明细'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsCarWorkSummary: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carNumber',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarWorkName(row)
                }
              }
            }, row.carNumber);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划计划天数',
          minWidth: 60,
          key: 'planDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          minWidth: 60,
          key: 'completeDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未完成天数',
          minWidth: 60,
          key: 'unCompleteDays',
          tooltip: true,
          align: 'center',
        },
        {
          title: '中转站次数',
          minWidth: 60,
          key: 'collectStandNumber',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.collectStandNumber) {
              return h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onClickCollectStandNumber(row)
                  }
                }
              }, row.collectStandNumber);
            }
            return h('span', 0);
          }
        },
        {
          title: '处理站次数',
          minWidth: 60,
          key: 'processStandNumber',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.processStandNumber) {
              return h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onClickProcessStandNumber(row)
                  }
                }
              }, row.processStandNumber);
            }
            return h('span', 0);
          }
        },
        {
          title: '报警次数',
          minWidth: 60,
          key: 'alarmNumber',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规次数',
          minWidth: 60,
          key: 'violationsNumber',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsCarWorkDetail: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carNumber',
          tooltip: true,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.carNumber) {
              return h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onClickDetailCarNumber(row)
                  }
                }
              }, row.carNumber);
            }
            return h('span', 0);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实施计划天数',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未完成天数',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '预计里程(KM)',
          minWidth: 60,
          key: 'expectMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶里程(KM)',
          minWidth: 60,
          key: 'mileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶时长',
          minWidth: 60,
          key: 'timeConsuming',
          tooltip: true,
          align: 'center'
        },
        {
          title: '耗油量(L)',
          minWidth: 60,
          key: 'oilConsumption',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警次数',
          minWidth: 60,
          key: 'alarmNumber',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规次数',
          minWidth: 60,
          key: 'violationsNumber',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsCarWorkCorrect: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center',
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          minWidth: 60,
          key: 'siteTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点名称',
          minWidth: 60,
          key: 'siteName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '转运量(纠正前/纠正后:T)',
          width: 180,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.ranpOld + '/' + row.ranpNew)
          }
        },
        {
          title: '进场时间(纠正前/纠正后)',
          width: 180,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.intimeOld + '/' + row.intimeNew)
          }
        },
        {
          title: '出场时间(纠正前/纠正后)',
          width: 180,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.outtimeOld + '/' + row.outtimeNew)
          }
        },
        {
          title: '进场车重(纠正前/纠正后)',
          width: 180,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.inweightOld + '/' + row.inweightNew)
          }
        },
        {
          title: '出场车重(纠正前/纠正后)',
          width: 180,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.outweightOld + '/' + row.outweightNew)
          }
        },
        {
          title: '纠正人',
          minWidth: 60,
          key: 'createName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正时间',
          minWidth: 60,
          key: 'createTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正理由',
          minWidth: 60,
          key: 'rectifyReason',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsTransitSummary: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '站点名称',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计转出车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计转出量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsTransitDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '站点名称',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计转出车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计转出量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsTransitCorrect: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'manageName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点名称',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '转运量(纠正前/纠正后:T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '进场时间(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出场时间(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '进场车重(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出场车重(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正人',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正时间',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正理由',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsDisposalSummary: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '站点名称',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsDisposalDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '站点名称',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集车次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计收集量(T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsDisposalCorrect: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'manageName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '站点名称',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '转运量(纠正前/纠正后:T)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '进场时间(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出场时间(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '进场车重(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出场车重(纠正前/纠正后)',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正人',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正时间',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '纠正理由',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsViolationSummary: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickViolationCarName(row)
                }
              }
            }, row.carName);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计违规次数',
          minWidth: 60,
          key: 'hjwgcs',
          tooltip: true,
          align: 'center'
        },
        {
          title: '停留',
          minWidth: 60,
          key: 'tl',
          tooltip: true,
          align: 'center'
        },
        {
          title: '速度限制',
          minWidth: 60,
          key: 'sdxz',
          tooltip: true,
          align: 'center'
        },
        {
          title: '非法倾倒',
          minWidth: 60,
          key: 'ffqd',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsViolationDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车型',
          minWidth: 60,
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          minWidth: 60,
          key: 'ruleTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规时间',
          minWidth: 60,
          key: 'time',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规地点',
          minWidth: 60,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规内容',
          minWidth: 60,
          key: 'content',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      // Echarts
      options: {
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        dataset: {
          source: []
        },
        series: [
          {
            type: 'bar'
          }
        ]
      },
      violateChartData: [],
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 完成度
      gridCompleteVisible: false,
      gridCompleteType: null,
      mileage: '',   // 里程
      point: '',   // 点位
      // 作业车辆详情 点击车牌号弹出弹窗
      mechanicalCleanPlanDetailVisible: false,
      mechanicalCleanPlanDetailItem: null,
      mechanicalCleanPlanDetailWorkType: null,
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickCurrentMonth()
      this.currentCarWorkTabIndex = 0
      this.currentViolationTabIndex = 0
    },
    currentCarWorkTabIndex (newVal) {
      if (newVal == 0) {
        this.carNumber = ''
        this.carNumberId = ''
        this.isSummaryClick = false
      }
      this.onClickSearch()
    },
    currentViolationTabIndex (newVal) {
      if (newVal == 0) {
        this.carNumber = ''
        this.carNumberId = ''
        this.isSummaryClick = false
      }
      this.onClickSearch()
    },
  },
  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    carTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              columns = this.columnsCarWorkSummary
              break;
            case 1:
              columns = this.columnsCarWorkDetail
              break;
            case 2:
              columns = this.columnsCarWorkCorrect
              break;
            default:
              break;
          }
          break;
        // case 1:
        //   switch (this.currentTransitTabIndex) {
        //     case 0:
        //       columns = this.columnsTransitSummary
        //       break;
        //     case 1:
        //       columns = this.columnsTransitDetail
        //       break;
        //     case 2:
        //       columns = this.columnsTransitCorrect
        //       break;
        //     default:
        //       break;
        //   }
        //   break;
        // case 2:
        //   switch (this.currentDisposalTabIndex) {
        //     case 0:
        //       columns = this.columnsDisposalSummary
        //       break;
        //     case 1:
        //       columns = this.columnsDisposalDetail
        //       break;
        //     case 2:
        //       columns = this.columnsDisposalCorrect
        //       break;
        //     default:
        //       break;
        //   }
        //   break;
        case 1:
          switch (this.currentViolationTabIndex) {
            case 0:
              columns = this.columnsViolationSummary
              break;
            case 1:
              columns = this.columnsViolationDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              path = this.$http.exportCarWorkLjzySummaryList
              break;
            case 1:
              path = this.$http.exportCarWorkLjzyDetailList
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
    ]),
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 370
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 370
      }
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.onClickCurrentMonth()
      this.getViolateTypeList()
      this.getCompleteness()
    },
    // 获取完成度
    getCompleteness () {
      this.$store.dispatch('getTimeCompleteDetail', 5).then(res => {
        this.mileage = res.kmOkThreshold
        this.path = res.routeOkThreshold
        this.point = res.pointOkThreshold
      })
    },
    // 设置完成度
    onClickComplete () {
      this.gridCompleteVisible = true
      this.gridCompleteType = 5
    },
    // 获取违规类型列表
    getViolateTypeList () {
      this.$http.getWorkRuleItemList({ type: 5 }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.violateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.customName
            item.name = item.customName
            return item
          }))
        }
      })
    },
    // 今天
    onClickToday () {
      this.date = [getToday(), getToday()]
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 昨天
    onClickYesterday () {
      this.date = [getYesterday(), getYesterday()]
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      let time = getLastMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 4
    },
    // 查询分类回调
    onChangeSelectType (value) {
      if (value == 1) {
        this.date = getCurrentMonth()
      } else {
        let time = getCurrentMonth()[0].split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.dateMonth = time[0] + '-' + time[1]
        this.date[0] = time[0] + '-' + time[1] + '-01'
        this.date[1] = time[0] + '-' + time[1] + '-' + day
      }
    },
    // 车辆类型回调
    onChangeCarType (data) {
      if (data) {
        this.carType = data.value
        this.carTypeName = data.label
      } else {
        this.carType = ''
        this.carTypeName = ''
      }
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 点击车辆作业汇总表格中车辆名称
    onClickCarWorkName (row) {
      // this.carNumberId = row.carId
      this.carNumber = row.carNumber
      this.currentCarWorkTabIndex = 1
    },
    // 中转站次数
    onClickCollectStandNumber (row) {
      this.mechanicalCleanPlanDetailWorkType = 4
      this.mechanicalCleanPlanDetailItem = row
      this.mechanicalCleanPlanDetailVisible = true
    },
    // 处理站次数
    onClickProcessStandNumber (row) {
      this.mechanicalCleanPlanDetailWorkType = 5
      this.mechanicalCleanPlanDetailItem = row
      this.mechanicalCleanPlanDetailVisible = true
    },
    // 明细点击车牌号
    onClickDetailCarNumber (row) {
      this.mechanicalCleanPlanDetailWorkType = 7
      this.mechanicalCleanPlanDetailItem = row
      this.mechanicalCleanPlanDetailVisible = true
    },
    // 点击车辆作业汇总表格中车辆完成天数
    onClickCarWorkFinish (row) {
      this.status = 3
      // this.carNumberId = row.carId
      this.carNumber = row.carNumber
      this.currentCarWorkTabIndex = 1
    },
    // 点击车辆作业汇总表格中车辆完成天数
    onClickCarWorkUnFinish (row) {
      this.status = 1
      // this.carNumberId = row.carId
      this.carNumber = row.carNumber
      this.currentCarWorkTabIndex = 1
    },
    // 点击违规统计表格中车辆名称
    onClickViolationCarName (row) {
      this.carNumberId = row.carId
      this.carNumber = row.carName
      this.currentViolationTabIndex = 1
    },
    // 明细单选框回调
    onChangeRadio () {
      this.onClickSearch()
    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
    },// 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      if (this.currentTabIndex == 1) {
        this.getEcharts()
      }
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置搜索条件
    resetSearchData () {
      // 车辆
      this.carType = ''
      this.carTypeName = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.remoteCar = ''

      this.planName = ''

      // 违规
      this.violateType = ''

      this.status = ''
      this.date = []
      // this.currentTabIndex = 0
      switch (this.currentTabIndex) {
        case 0:
          if (this.currentCarWorkTabIndex != 2) {
            this.currentCarWorkTabIndex = 0
          }
          break;
        // case 1:
        //   break;
        // case 2:
        //   break;
        case 1:
          this.currentViolationTabIndex = 0
          break;
        default:
          break;
      }
      this.buttonIndex = null
    },
    // 日期回调
    onChangeDate (date) {
      if (this.selectType == 1) {
        this.date = date
      } else {
        let time = date.split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.date[0] = date + '-01'
        this.date[1] = date + '-' + day
      }
      this.buttonIndex = null
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              this.getCarWorkSummaryList()
              break;
            case 1:
              this.getCarWorkDetailList()
              break;
            case 2:
              this.getCarWorkCorrectList()
              break;
            default:
              break;
          }
          break;
        case 1:
          break;
        case 2:
          break;
        case 1:
          switch (this.currentViolationTabIndex) {
            case 0:
              this.getViolationSummaryList()
              break;
            case 1:
              this.getViolationDetailList()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // status 3完成 1未完成 0全部
    // 车辆汇总列表
    getCarWorkSummaryList () {
      let params = {
        status: '',
        carType: this.carType == 0 ? '' : this.carTypeName,
        carNumber: this.carNumber,
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        // funType: 3
      }
      this.tableLoading = true
      this.$http.getCarWorkLjzySummaryList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆明细列表
    getCarWorkDetailList () {
      let params = {
        status: this.status,
        carType: this.carType == 0 ? '' : this.carTypeName,
        carNumber: this.carNumber,
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        // funType: 3
      }
      this.tableLoading = true
      this.$http.getCarWorkLjzyDetailList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆 纠正记录列表
    getCarWorkCorrectList () {
      let params = {
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.carNumberId,
        siteId: '',
        siteName: '',
        createUid: '',
        createName: '',
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
      }
      this.tableLoading = true
      this.$http.getGarbageTransitCorrectList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },

    // 违规统计汇总列表
    getViolationSummaryList () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        module: 4
      }
      this.tableLoading = true
      this.$http.getViolationSummaryList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规统计明细列表
    getViolationDetailList () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        startDate: this.date[0],
        endDate: this.date[1],
        gridId: '',
        page: this.pageNum,
        size: this.pageSize,
        module: 4
      }
      this.tableLoading = true
      this.$http.getCarViolationList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规统计的图表
    getEcharts () {
      let params = {
        ruleTypeName: this.violateType === 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        startDate: this.date[0],
        endDate: this.date[1],
        module: 4
      }
      this.$http.getViolationEcharts(params).then(res => {
        if (res.code === 200) {
          if (res.result.length) {
            let arr = [
              ['车辆名称', '车辆违规']
            ]
            this.violateChartData = arr.concat(res.result.map(item => {
              return [item.car_name, item.count]
            }))
          } else {
            this.violateChartData = []
          }
        }
      })
    },

    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              data = {
                status: '',
                carType: this.carType == 0 ? '' : this.carTypeName,
                carNumber: this.carNumber,
                startDate: this.date[0],
                endDate: this.date[1],
                page: this.pageNum,
                size: this.pageSize,
              }
              if (value == 'all') {
                data.size = '99999'
              }
              break;
            case 1:
              data = {
                status: this.status,
                carType: this.carType == 0 ? '' : this.carTypeName,
                carNumber: this.carNumber,
                startDate: this.date[0],
                endDate: this.date[1],
                page: this.pageNum,
                size: this.pageSize,
              }
              if (value == 'all') {
                data.size = '99999'
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
    .noData {
      height: 368px;
      img {
        width: 172px;
        height: 100px;
      }
    }
  }
}
</style>